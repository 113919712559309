import React, { useState } from 'react'
import validator from 'validator';
import '../../scss/components/generic/newsletter.scss';
import { graphql, useStaticQuery } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getResource } from "../../helpers/resourceHelper";
import { useI18next } from "gatsby-plugin-react-i18next";
import GenericTextbox from './genericTextbox';
import addToMailchimp from 'gatsby-plugin-mailchimp'

const query = graphql`
  query newsletterSubsQuery {
    resources: allContentfulResource(
      filter: { key: { in: ["newsletter.subscribe.error", "newsletter.subscribe.validation", "newsletter.subscribe.label", "newsletter.subscribe.placeholder", "newsletter.subscribe.button"] } }
    ) {
      edges {
        node {
          value {
            raw
          }
          node_locale
          key
        }
      }
    }
  }
`;

function NewsletterSubscription() {
  const { resources } = useStaticQuery(query);
  const { language } = useI18next();
  const [input, setInput] = useState(null);
  const [sent, setSent] = useState(false);
  const [suceeded, setSucceeded] = useState(false);
  const [emailError, setEmailError] = useState(null);

  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError(null);
      setInput(email);
    } else {
      setEmailError(documentToReactComponents(getResource(resources.edges, 'newsletter.subscribe.validation', language)));
    }
  }

  const subscribe = async () => {
    document.getElementById("loader").style.display = "block";

    setSent(true);
    const response = await addToMailchimp(input);
    response.result === "success" ?
      setSucceeded(true) :
      setEmailError(documentToReactComponents(getResource(resources.edges, 'newsletter.subscribe.error', language)));

    document.getElementById("loader").style.display = "none";
  }

  return (<div className="newsletter-container">
    <div class="newsletter-text">{documentToReactComponents(getResource(resources.edges, 'newsletter.subscribe.label', language))}</div>
    <div className={`newsletter-form`}>
      <GenericTextbox
        type="email"
        errorMessage={emailError}
        placeholder={getResource(resources.edges, 'newsletter.subscribe.placeholder', language, "string")}
        className="email-input"
        required
        onKeyUp={e => validateEmail(e)} />
      {sent && suceeded && <svg xmlns="http://www.w3.org/2000/svg" width="19.243" height="13.621" viewBox="0 0 19.243 13.621" class="approve-icon-hovering">
        <path id="Path_885" data-name="Path 885" d="M5,12l5,5L20,7" transform="translate(-2.879 -4.879)" fill="none" stroke="#3c763d" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
      </svg>}
      <button className="v-info-button" onClick={() => emailError == null ? subscribe() : null}>{getResource(resources.edges, 'newsletter.subscribe.button', language, "string")}</button>
    </div>
  </div>);
}

export default NewsletterSubscription;
