import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getResource } from "../helpers/resourceHelper";
import { useI18next } from "gatsby-plugin-react-i18next";
import {
  getAnimationDelay,
  getAnimationDuration,
} from ".././helpers/animationHelper";

const query = graphql`
  query Pagination {
    resources: allContentfulResource(
      filter: { key: { in: ["pagination.loadmore.label"] } }
    ) {
      edges {
        node {
          value {
            raw
          }
          node_locale
          key
        }
      }
    }
  }
`;

const PaginatedList = (props) => {
  const { pageSize, items } = props;
  const { language } = useI18next();
  const { resources } = useStaticQuery(query);
  const [list, setList] = useState([...items.slice(0, pageSize)]);
  const [hasMore, setHasMore] = useState(items.length > pageSize);
  const [loadMore, setLoadMore] = useState(false);

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  // Handle loading more items
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < items.length;
      const nextResults = isMore
        ? items.slice(currentLength, currentLength + pageSize)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore, items, list, pageSize]);

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < items.length;
    setHasMore(isMore);
  }, [list, items.length]);

  return (
    <div 
    data-sal="slide-up"
    data-sal-delay={getAnimationDelay()}
    data-sal-duration={getAnimationDuration()}
    data-sal-easing="ease" className={props.className}>
      {list.map((item, index) => {
        return props.mapItem(item, index);
      })}
      {hasMore && (
        <div className="load-more-button-container">
          <button
            className="v-info-button v-load-more"
            onClick={handleLoadMore}
          >
            {documentToReactComponents(
              getResource(
                resources.edges,
                "pagination.loadmore.label",
                language
              )
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default PaginatedList;
