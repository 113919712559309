export default {
  fairDateFormat: {
    year: "numeric",
    month: "long",
  },
  fairDetailDateFormat: {
    day: "numeric",
    year: "numeric",
    month: "long",
  },
};
