import React, { useState } from 'react'
import { IoMdPin } from 'react-icons/io';
import { MdOutlineEvent } from 'react-icons/md';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { graphql, useStaticQuery } from "gatsby";
import '../../scss/components/generic/fairItem.scss';
import { useI18next } from 'gatsby-plugin-react-i18next';
import dateConstants from '../../constants/dateConstants';
import { renderRichText } from '../../helpers/richTextHelper';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { getResource } from '../../helpers/resourceHelper';
import { generateGoogleCalendarUrl } from '../../helpers/calendarHelper';

const query = graphql`
  query FairItem {
    resources: allContentfulResource(filter: { key: { in: ["fairs.button.addToCalendar.label", "fairs.button.details.label"] } }) {
      edges {
        node {
          value {
            raw
          }
          node_locale
          key
        }
      }
    }
  }
`;

const FairItem = ({ fair }) => {
    const { language } = useI18next();
    const [showDetails, setShowDetails] = useState(false);
    const { resources } = useStaticQuery(query);
    let calendarUrl = generateGoogleCalendarUrl(fair.startDate, fair.endDate, fair.name);

    let getDateTag = (date) => {
        let dateObj = new Date(date);
        let day = dateObj.getDate();
        let monthYear = dateObj.toLocaleDateString(language, dateConstants.fairDateFormat);

        return <div className="fair-item__date">
            <div className="fair-item__day">{day}</div>
            <div className="fair-item__year">{monthYear}</div>
        </div>
    }

    let isPastFair = (date) => {
        let dateObj = new Date(date);
        return dateObj < new Date();
    }

    let toggleDetails = () => {
        setShowDetails(!showDetails);
    }

    return <div className={`fair-item__container${isPastFair(fair.endDate) ? ' is-past' : ''}`}>
        <div className="fair-item__base-container">
            <div className="fair-item__date-container">
                {getDateTag(fair.startDate)}
                <div className="fair-item__date-separator" />
                {getDateTag(fair.endDate)}
            </div>
            <div className="separator" />
            <div className="fair-item__details">
                <div className="fair-item__name">{fair.name}</div>
                <div className="fair-item__location-container">
                    <IoMdPin />
                    <div className="fair-item__location">{fair.location}</div>
                </div>
                {
                    !isPastFair(fair.endDate) &&
                    <div className="fair-item__buttons">
                        <div className="fair-item__add-to-calendar-container">
                            <MdOutlineEvent />
                            <a href={calendarUrl} rel="noreferrer" target="_blank" className="fair-item__button">{documentToReactComponents(getResource(resources.edges, 'fairs.button.addToCalendar.label', language))}</a>
                        </div>
                    </div>
                }
            </div>
            {!isPastFair(fair.endDate) && showDetails && <FiChevronUp className="icon-chevron" onClick={() => toggleDetails()} />}
            {!isPastFair(fair.endDate) && !showDetails && <FiChevronDown className="icon-chevron" onClick={() => toggleDetails()} />}
        </div>
        {
            showDetails && <div className="fair-item__detail-container">
                <div className="fair-item__detail-header">
                    <div className="fair-item__detail-head">{fair.title}</div>
                </div>
                <div className="fair-item__detail">{renderRichText(fair.description.raw)}</div>
            </div>
        }
    </div>
}

export default FairItem;
