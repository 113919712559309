const googleCalendarBaseUrl = "https://www.google.com/calendar/render?action=TEMPLATE";

export function generateGoogleCalendarUrl(startDate, endDate, title) {
    let endDateObj = new Date(endDate);
    endDateObj.setDate(endDateObj.getDate() + 1);
    endDate = endDateObj.toJSON();

    let eventStartDate = startDate.replace(/-/g, '');
    let eventEndDate = endDate.replace(/-/g, '');
    let eventTitle = title.replace(/ /g, '%20');
    return `${googleCalendarBaseUrl}&text=${eventTitle}&dates=${eventStartDate}/${eventEndDate}`;
};

export default {
    generateGoogleCalendarUrl: generateGoogleCalendarUrl
}
