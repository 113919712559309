import React from "react";
import BrandBox from "./brandBox";
import "../../scss/components/list/ourBrands.scss";
import { renderRichText } from "../../helpers/richTextHelper";
import {
  getAnimationDelay,
  getAnimationDuration,
} from "../../helpers/animationHelper";

const OurBrands = ({ data }) => {
  return (
    <div className="our-brands-container">
      <div className="our-brands__title-container">
        <h2
          className="hero-text"
          data-sal="slide-up"
          data-sal-delay={getAnimationDelay("slow")}
          data-sal-duration={getAnimationDuration()}
          data-sal-easing="ease"
        >
          {data.title}
        </h2>
        <div
          data-sal="slide-up"
          data-sal-delay={getAnimationDelay("slow")}
          data-sal-duration={getAnimationDuration()}
          data-sal-easing="ease"
        >
          {renderRichText(data.description.raw)}
        </div>
      </div>
      <div
        className="our-brands__brand-boxes-container"
        data-sal="slide-up"
        data-sal-delay={getAnimationDelay("slow")}
        data-sal-duration={getAnimationDuration()}
        data-sal-easing="ease"
      >
        <div className="our-brand__images-container">
          {data.items &&
            data.items.map((item, index) => {
              return <BrandBox item={item} index={index} />;
            })}
        </div>
      </div>
    </div>
  );
};

export default OurBrands;
