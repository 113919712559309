import React from "react";
import Steps from "../components/steps";
import Hero from "../components/hero";
import Video from "../components/video";
import SpotlightFocus from "../components/spotlightFocus";
import ProductTabList from "../components/productTabList";
import ItemBoxes from "../components/List/itemBoxes";
import Slider from "../components/Slider/slider";
import Generic from "../components/Generic/generic";
import ImageBoxes from "../components/imageBoxes";
import Quote from "../components/quote";
import ImageWithText from "../components/imageWithText";
import AlternatingTextWithImage from "../components/alternatingTextWithImage";
import FloatingImageWithVideoAndText from "../components/floatingImageWithVideoAndText";
import RelatedBoxes from "../components/List/relatedBoxes";
import BrandBoxes from "../components/List/ourBrands";

function ComponentResolver({ component }) {
  switch (component.__typename) {
    case "ContentfulHero":
      return <Hero data={component} />;
    case "ContentfulList":
      switch (component.type) {
        case "Spotlight":
          return <SpotlightFocus spotlightList={component} />;
        case "Steps":
          return <Steps stepList={component} />;
        case "Product Tabs":
          return <ProductTabList productList={component} />;
        case "Navigation Boxes":
        case "Item Boxes":
        case "Large Item Boxes":
        case "Reference Boxes":
          return <ItemBoxes data={component} />;
        case "Brand Boxes":
          return <BrandBoxes data={component} />;
        default:
          return null;
      }
    case "ContentfulSlider":
      return <Slider data={component} />;
    case "ContentfulVideo":
      return <Video data={component} />;
    case "ContentfulGenericComponent":
      return <Generic data={component} />;
    case "ContentfulImageBoxes":
      return <ImageBoxes data={component} />;
    case "ContentfulQuote":
      return <Quote data={component} />;
    case "ContentfulImageWithText":
      return <ImageWithText data={component} />;
    case "ContentfulAlternatingTextWithImage":
      return <AlternatingTextWithImage data={component} />;
    case "ContentfulFloatingImageWithVideoAndText":
      return <FloatingImageWithVideoAndText data={component} />;
    case "ContentfulRelatedItemBoxes":
      return <RelatedBoxes data={component} />;
    default:
      return null;
  }
}

export function resolveComponents(bodySections) {
  if (bodySections && bodySections.length > 0) {
    return bodySections.map((data, index) => {
      return <ComponentResolver key={index} component={data} />;
    });
  }

  return [];
}

export default ComponentResolver;
