import React from 'react'
import GoogleMapReact from 'google-map-react'
import { useI18next } from "gatsby-plugin-react-i18next";
import Marker from './mapMarker';

const Map = (props) => {
    const { language } = useI18next();

    return (
        <div className={props.className}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_APIKEY, language: language.substr(0, 2) }}
                defaultCenter={[props.lat, props.lng]}
                defaultZoom={14}
            >
                <Marker
                    lat={props.lat}
                    lng={props.lng}
                />
            </GoogleMapReact>
        </div>
    );
}

export default Map;