import React from 'react'
import '../../scss/components/generic/loadingAnimation.scss';

const LoadingAnimation = () => {
    return (
        <div id="loader" className="loading-area">
            <div class="lds-dual-ring"></div>
        </div>
    );
}

export default LoadingAnimation;



