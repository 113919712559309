import React from 'react'
import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from 'gatsby-plugin-react-i18next';
import FairItem from './fairItem';
import '../../scss/components/generic/fairs.scss';
import languageHelper from '../../helpers/languageHelper';
import PaginatedList from '../paginatedList';
import {
  getAnimationDelay,
  getAnimationDuration,
} from "../../helpers/animationHelper";

const query = graphql`
query Fairs {
  fairs: allContentfulFair(limit: 100, sort: {order: DESC, fields: startDate}) {
    nodes {
      description {
          raw
      }
      endDate
      location
      name
      node_locale
      startDate
      title
    }
  }
}
`;

function Fairs() {
  const { fairs } = useStaticQuery(query);
  const { language } = useI18next();
  const fairList = languageHelper.getCurrentLanguageNodes(fairs, language);

  let renderFairItem = (fair, index) => {
    return <FairItem fair={fair} index={index} />
  }

  return (
                  <div
                  className="fairs-container"
                  data-sal="slide-up"
                  data-sal-delay={getAnimationDelay("medium")}
                  data-sal-duration={getAnimationDuration()}
                  data-sal-easing="ease"
              >
              <PaginatedList className="fairs_list_container" pageSize={5} items={fairList} mapItem={renderFairItem} />
            </div>
  );
}

export default Fairs;
