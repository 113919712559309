import React from "react";
import "../scss/components/floatingImageWithVideoAndText.scss";
import ImageWithFocalPoint from "./imageWithFocalPoint";
import { renderRichText } from "../helpers/richTextHelper";
import ReactPlayer from "react-player/lazy";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const FloatingImageWithVideoAndText = ({ data }) => {
  const breakpoints = useBreakpoint();

  return (
    <div className="floating-image-with-video-text-container">
      <div className="text-column">
        {data.image && (
          <ImageWithFocalPoint className="main-image" image={data.image} />
        )}
        <h3>{data.title}</h3>
      </div>
      <div className="video-column">
        <ReactPlayer
          className="video-player"
          url={data.videoUrl}
          height={breakpoints.sm ? 172 : breakpoints.md ? 395 : 252}
          width="auto"
          muted={true}
          loop={true}
          playing={true}
          controls={true}
        />
        {data.description && (
          <div className="description">
            {renderRichText(data.description.raw)}
          </div>
        )}
      </div>
    </div>
  );
};

export default FloatingImageWithVideoAndText;
