//alternatingTextWithImage
import React from "react";
import "../scss/components/alternatingTextWithImage.scss";
import ImageWithFocalPoint from "./imageWithFocalPoint";
import { renderRichText } from "../helpers/richTextHelper";
import {
    getAnimationDelay,
    getAnimationDuration,
  } from ".././helpers/animationHelper";

const AlternatingTextWithImage = ({ data }) => {

    return (
        <div
        className={`alternating-text-with-image-container ${data.isTextRightAligned ? "right-aligned" : ""} `}
        data-sal="slide-up"
        data-sal-delay={getAnimationDelay("slow")}
        data-sal-duration={getAnimationDuration()}
        data-sal-easing="ease"
         >
        <div className="text-column">
            {data.description && renderRichText(data.description.raw)}
        </div>
        <div className="image-column">
            {data.image && <ImageWithFocalPoint className="main-image" image={data.image} />}
        </div>
    </div>);
}

export default AlternatingTextWithImage;