import React, { useState } from 'react'
import { graphql, useStaticQuery } from "gatsby";
import axios from 'axios';
import { useI18next } from "gatsby-plugin-react-i18next";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getResource } from "../../helpers/resourceHelper";
import { INLINES } from "@contentful/rich-text-types";
import { Link } from "gatsby-plugin-react-i18next";
import { GrCheckmark } from 'react-icons/gr';
import '../../scss/components/generic/contactForm.scss';
import Button from '../button';
import Regex from "../../constants/regex";
import {
    getAnimationDelay,
    getAnimationDuration,
  } from "../../helpers/animationHelper";

const query = graphql`
  query contactFormQuery {
    resources: allContentfulResource(
      filter: { key: { in: [
          "contactform.title", 
          "contactform.description", 
          "contactform.firstname.placeholder", 
          "contactform.lastname.placeholder",
          "contactform.subject.placeholder",
          "contactform.description.placeholder",
          "contactform.termsConditions.label",
          "contactform.button.send.text",
          "contactform.success.title",
          "contactform.success.desciption",
          "contactform.success.button.home.text",
          "contactform.validation.errormessage",
          "contactform.validation.emailerrormessage",
          "contactform.validation.termsagreed",
          "contactform.email.placeholder"] } }
    ) {
      edges {
        node {
          value {
            raw
          }
          node_locale
          key
        }
      }
    }
  }
`;

const ContactForm = () => {
    const { resources } = useStaticQuery(query);
    const { language } = useI18next();
    const [input, setInput] = useState({
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        description: ''
    });
    const [hasError, setHasError] = useState(false);
    const [hasValidEmail, setHasValidEmail] = useState(false);
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [validationTriggered, setValidationTriggered] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);    

    const options = {
        renderNode: {
            [INLINES.HYPERLINK]: (node, children) => {
                return <Link className="contact-form__hyperlink" to={node.data.uri}>{children[0]}</Link>
            }
        }
    };

    const inputsHandler = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value })
    }

    const validateForm = () => {
        // first validate if terms and conditions are agreed.
        if (!termsAgreed) return false;

        //then validate the fields
        if (!input.firstName || !input.lastName || !input.subject || !input.description || !input.email) {
            return false;
        }

        return true;

    }

    const validateEmail = () => {
        // first validate if terms and conditions are agreed. 
        return Regex.emailRegex.value.test(input.email);
    }
 

    const handleSubmit = () => {
        // If is invalid, then return and show error messages.
        if (!validateForm()) {
            setHasError(true);
            setHasValidEmail(true);
            setValidationTriggered(true);
            return;
        }
        if (!validateEmail()) {
            setHasError(true);
            setHasValidEmail(false);
            setValidationTriggered(true);
            return;
        }




        let request = {
            firstName: input.firstName,
            lastName: input.lastName,
            subject: input.subject,
            description: input.description,
            emailAddress: input.email
        };

        document.getElementById("loader").style.display = "block";

        axios.post("/api/contactform", request)
            .then(response => {
                setIsSuccess(true);
            })
            .catch(error => {
                setIsSuccess(false);
            })
            .finally(() => {
                document.getElementById("loader").style.display = "none";
            });
    };

    const renderSuccessState = () => {
        return <div className="contact-form-success">
            <div className="contact-form-success__container">
                <div className="contact-form-success__check-icon-container">
                    <GrCheckmark className="contact-form-success__check-icon" />
                </div>
                <h2>{getResource(resources.edges, 'contactform.success.title', language, 'string')}</h2>
                <div className="contact-form-success__description">{documentToReactComponents(getResource(resources.edges, 'contactform.success.desciption', language))}</div>
                <Button to="/" title={getResource(resources.edges, 'contactform.success.button.home.text', language, 'string')} />
            </div>
        </div>
    }

    const renderInitialState = () => {
        return <div className="contact-form">
            <h2>{getResource(resources.edges, 'contactform.title', language, 'string')}</h2>
            <div className="contact-form__description">{documentToReactComponents(getResource(resources.edges, 'contactform.description', language))}</div>
            <div className="contact-form__form">
                <div className="contact-form__row">
                    <input
                        placeholder={getResource(resources.edges, 'contactform.firstname.placeholder', language, 'string')}
                        name="firstName"
                        className={`contact-form__input generic-textbox${validationTriggered && !input.firstName ? ' invalid' : ''}`}
                        value={input.firstName}
                        onChange={inputsHandler} />
                    <input
                        placeholder={getResource(resources.edges, 'contactform.lastname.placeholder', language, 'string')}
                        name="lastName"
                        className={`contact-form__input generic-textbox${validationTriggered && !input.lastName ? ' invalid' : ''}`}
                        value={input.lastName}
                        onChange={inputsHandler} />
                </div>
                <div className="contact-form__row">
                    <input
                        type="email"
                        placeholder={getResource(resources.edges, 'contactform.email.placeholder', language, 'string')}
                        name="email"
                        className={`contact-form__input generic-textbox${validationTriggered && !input.email ? ' invalid' : ''}`}
                        value={input.email}
                        onChange={inputsHandler} />
                    <input
                        placeholder={getResource(resources.edges, 'contactform.subject.placeholder', language, 'string')}
                        name="subject"
                        className={`contact-form__input generic-textbox${validationTriggered && !input.subject ? ' invalid' : ''}`}
                        value={input.subject}
                        onChange={inputsHandler} />
                </div>
                <div className="contact-form__row">
                    <textarea
                        placeholder={getResource(resources.edges, 'contactform.description.placeholder', language, 'string')}
                        name="description"
                        className={`contact-form__input generic-textbox textarea${validationTriggered && !input.description ? ' invalid' : ''}`}
                        value={input.description}
                        onChange={inputsHandler} />
                </div>
                {hasError && <div className="contact-form__validation-message">
                    {termsAgreed && hasValidEmail && getResource(resources.edges, 'contactform.validation.errormessage', language, 'string')}
                    {termsAgreed && !hasValidEmail &&  getResource(resources.edges, 'contactform.validation.emailerrormessage', language, 'string')}
                    {!termsAgreed && getResource(resources.edges, 'contactform.validation.termsagreed', language, 'string')}
                </div>}
                <div className="contact-form__row checkbox">
                    <input name="termsConditions" type="checkbox" onChange={(e) => setTermsAgreed(e.target.checked)} />
                    <label for="termsConditions">{documentToReactComponents(getResource(resources.edges, 'contactform.termsConditions.label', language), options)}</label>
                </div>

                     <div
                            className="v-button_container"
                            data-sal="slide-up"
                            data-sal-delay={getAnimationDelay("slow")}
                            data-sal-duration={getAnimationDuration()}
                            data-sal-easing="ease"
                      > 
                    <button onClick={handleSubmit} className="v-button">{getResource(resources.edges, 'contactform.button.send.text', language, 'string')}</button>
                    <svg
                        className={`v-button_arrow`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="33.871"
                        height="10.897"
                        viewBox="0 0 33.871 10.897"
                    >
                        <g id="Group_2483" data-name="Group 2483" transform="translate(-816.412 -4411.361)"
                        >
                            <line
                                id="Line_197"
                                data-name="Line 197"
                                x2="33.176"
                                transform="translate(816.412 4416.715)"
                                fill="none"
                                stroke="#bd9b60"
                                stroke-miterlimit="10"
                                stroke-width="1"
                            />
                            <path
                                id="Path_845"
                                data-name="Path 845"
                                d="M250.169,187.3l5.1,5.1-5.1,5.1"
                                transform="translate(594.312 4224.411)"
                                fill="none"
                                stroke="#bd9b60"
                                stroke-miterlimit="10"
                                stroke-width="1"
                            />
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    }

    return !isSuccess ? renderInitialState() : renderSuccessState();
}

export default ContactForm;
