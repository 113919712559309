import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import "../../scss/components/generic/referenceList.scss";
import ReferenceItem from "./referenceItem";
import languageHelper from "../../helpers/languageHelper";
import {
  getAnimationDelay,
  getAnimationDuration,
} from "../../helpers/animationHelper";

const query = graphql`
  query ReferenceList {
    referenceList: allContentfulReferenceItem(limit: 100) {
      nodes {
        subtitle {
          raw
        }
        slug
        node_locale
        name
        logo {
          gatsbyImageData(
            quality: 90
            formats: WEBP
            placeholder: BLURRED
            layout: CONSTRAINED
            width: 185
            height: 185
          )
        }
        backgroundImage {
          ... on ContentfulImageWithFocalPoint {
            ...FocalPointFragment
          }
        }
      }
    }
  }
`;

function ReferenceList() {
  const { referenceList } = useStaticQuery(query);
  const { language } = useI18next();
  const filteredList = languageHelper.getCurrentLanguageNodes(
    referenceList,
    language
  );
  return (
      filteredList && filteredList.map((item, index) => {
          return [
          <div
              className="reference-list-container"
              data-sal="slide-up"
              data-sal-delay={getAnimationDelay() + index * 200}
              data-sal-duration={getAnimationDuration()}
              data-sal-easing="ease"
           >
             <ReferenceItem referenceItem={item} index={index} />;
           </div>
          ]
        })          
  );
}

export default ReferenceList;
