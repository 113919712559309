import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { renderRichText } from "../helpers/richTextHelper";
import "../scss/components/productTabList.scss";
import { useI18next } from "gatsby-plugin-react-i18next";
import Button from "./button";
import { getResource } from "../helpers/resourceHelper";
import ImageWithFocalPoint from "./imageWithFocalPoint";
import ReactSlider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {
  getAnimationDelay,
  getAnimationDuration,
} from ".././helpers/animationHelper";

const query = graphql`
  query ProductTabList {
    resources: allContentfulResource(
      filter: { key: { in: ["productTabList.moreInformation.label"] } }
    ) {
      edges {
        node {
          value {
            raw
          }
          node_locale
          key
        }
      }
    }
  }
`;

const ProductTabList = ({ productList }) => {
  const CustomTab = ({ onClick, item, index }) => {
    return (
      <div
        role="button"
        tabIndex={index}
        onClick={onClick}
        onKeyDown={onClick}
        className={`product-tab-list__tab-container${index === selectedTab ? " selected" : " "
          }`}
      >
        <p className="product-tab-list__tab-title">{item.title}</p>
      </div>
    );
  };
  CustomTab.tabsRole = "Tab";
  const { resources } = useStaticQuery(query);
  const [selectedTab, setSelectedTab] = useState(0);
  const { language } = useI18next();
  const slider = React.useRef(null);
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <IoIosArrowBack />,
    nextArrow: <IoIosArrowForward />,
    afterChange: () => { },
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          infinite: true,
          slidesToShow: 1,
          centerPadding: "100px",
          centerMode: true,
          className: "center",
        },
      },
    ],
  };
  return (
    <div className="product-tab-list__container">
      <div className="product-tab-list__header">
              <h3 className="product-tab-list__title"
                  data-sal="slide-left"
                  data-sal-delay={getAnimationDelay()}
                  data-sal-duration={getAnimationDuration()}
                  data-sal-easing="ease">
                {productList.description && renderRichText(productList.description.raw)}
              </h3>
              <Button
              className="hero-text"
              animationDirection="slide-left"
              animationDelay={getAnimationDelay("slow")}
              title={productList.ctaLabel} to={productList.ctaUrl} />
      </div>
      <ReactSlider ref={slider} {...settings}>
        {productList.items &&
          productList.items.map((item, index) => {
            return (
              <CustomTab
                onClick={() => {
                  slider.current.slickGoTo(index);
                  setSelectedTab(index);
                }}
                item={item}
                index={index}
              />
            );
          })}
      </ReactSlider>

      {productList.items &&
        productList.items.map((item, index) => {
          return (
            index === selectedTab && (
              <div key={index} className="product-tab-list__product-container">
                <div className="product-tab-list__image-container">
                  <ImageWithFocalPoint
                    className="showcase-image"
                    image={item.showcaseImage}
                    alt={item.showcaseImage.altText}
                  />
                </div>

                <div className="product-tab-list__text-container">
                  <div>
                    <p className="product-tab-list__title">
                      {item.showcaseTitle}
                    </p>
                    <p className="product-tab-list__description">
                      {item.showcaseDescription && renderRichText(item.showcaseDescription.raw)}
                    </p>
                  </div>

                  <Button
                    title={getResource(
                      resources.edges,
                      "productTabList.moreInformation.label",
                      language,
                      "string"
                    )}
                    to={item.slug}
                  />
                </div>
              </div>
            )
          );
        })}
    </div>
  );
};

export default ProductTabList;
