import React, { useState } from 'react'
import queryString from 'query-string';
import { BsSearch } from 'react-icons/bs';
import { graphql, useStaticQuery } from "gatsby";
import { getResource } from '../../helpers/resourceHelper';
import { useI18next } from 'gatsby-plugin-react-i18next';
import './../../scss/components/generic/searchResult.scss'
import SearchClient from '../../integrations/algolia/searchClient';
import { InstantSearch, SearchBox, Index, Configure } from 'react-instantsearch-dom';
import HasQuery from './../Search/hasQuery';

const query = graphql`
query SearchResources {
  resources: allContentfulResource(filter: {key: {in: ["header.search.placeholder", "header.search.back"]}}) {
    edges {
      node {
        value {
          raw
        }
        node_locale
        key
      }
    }
  }
}
`;

const SearchResults = () => {
  const { language, navigate } = useI18next();
  const { resources } = useStaticQuery(query);

  const isBrowser = () => typeof window !== "undefined";
  const qs = isBrowser() && queryString.parse(window.location.search);

  const [searchText, setsearchText] = useState(qs.q);

  const onKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      event.preventDefault();
      event.stopPropagation();

      if (searchText && searchText !== '')
        navigate("/search-results/?q=" + searchText);
    }
  }

  const searchState = { query: qs.q };
  const resultFilters = `node_locale:${language}`;

  return <div className="search-results">
    <div className="search-bar">
      <div className="back-button" onClick={() => isBrowser() && window.history.back()}>{getResource(resources.edges, 'header.search.back', language, 'string')}</div>
      <BsSearch color="black" className="search-logo" />
      <input type="text" className="search-box" value={searchText} onChange={e => setsearchText(e.target.value)} placeholder={getResource(resources.edges, 'header.search.placeholder', language, 'string')} onKeyDown={(e) => onKeyDown(e)} />
    </div>
    <div className="results">
      <InstantSearch indexName="search_index" searchClient={SearchClient} searchState={searchState}>
        <Configure filters={resultFilters} />
        <SearchBox className="hide-search-box" />
        <div class="search-results">
          <Index indexName="search_index">
            <HasQuery />
          </Index>
        </div>
      </InstantSearch>
    </div>
  </div>;
};

export default SearchResults;