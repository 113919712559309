import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ImageWithFocalPoint from "../imageWithFocalPoint";
import "../../scss/components/list/referenceBox.scss";
import {
  getAnimationDelay,
  getAnimationDuration,
} from "../../helpers/animationHelper";

const ReferenceBox = ({ item, type, index }) => {
  return (
    <div
      className={`reference-box__item ${
        type === "Large Item Boxes" ? " xlarge" : " "
      }`}
      key={index}
    >
      <div
        key={index}
        data-sal={index % 2 ? "slide-left" : "slide-right"}
        data-sal-delay={getAnimationDelay()}
        data-sal-duration={getAnimationDuration()}
        data-sal-easing="ease"
      >
        {index % 2 ? (
          <div className="reference-box__container">
            <div
              className={`reference-box__logo-container${
                item.descriptionOnHover ? " do-flade" : " "
              }`}
            >
              {!item.logo ? (
                <p className="reference-box__reference-title">{item.title}</p>
              ) : (
                <GatsbyImage
                  image={getImage(item.logo)}
                  alt={item.logo.description}
                  className="reference-box__logo"
                />
              )}
            </div>
            <div className="vertical-line" />
            <ImageWithFocalPoint
              image={item.backgroundImage}
              alt={item.backgroundImage.altText}
              className="reference-box__image"
            />
            <div className="vertical-line" />
          </div>
        ) : (
          <div className="reference-box__container">
            <ImageWithFocalPoint
              image={item.backgroundImage}
              alt={item.backgroundImage.altText}
              className="reference-box__image"
            />
            <div
              className={`reference-box__logo-container${
                item.descriptionOnHover ? " do-flade" : " "
              }`}
            >
              {!item.logo ? (
                <p className="reference-box__reference-title">{item.title}</p>
              ) : (
                <GatsbyImage
                  image={getImage(item.logo)}
                  alt={item.logo.description}
                  className="reference-box__logo"
                />
              )}
            </div>
            <div className="vertical-line" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferenceBox;
