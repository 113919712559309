import React from 'react'
import { graphql, useStaticQuery } from "gatsby";
import { getResource } from "../../helpers/resourceHelper";

const query = graphql`
  query BackToTopButton {
    resources: allContentfulResource(
      filter: { key: { in: ["page.backtotop.label"] } }
    ) {
      edges {
        node {
          value {
            raw
          }
          node_locale
          key
        }
      }
    }
  }
`;

const BackToTopButton = (language) => {
    const { resources } = useStaticQuery(query);
    const buttonTitle = getResource(resources.edges, 'page.backtotop.label', language, 'string');
    return (
        <button id="scroll-btn" className="btt-button" title={buttonTitle} aria-label={buttonTitle}>{buttonTitle}</button>
    );
}

export default BackToTopButton;
