import React from "react";
import "../../scss/components/generic/newsItem.scss";
import { useI18next } from "gatsby-plugin-react-i18next";
import { getMonthlyFormattedDate } from "../../helpers/dateFormatHelper";
import ImageWithFocalPoint from "../imageWithFocalPoint";
import Button from "../button";
import { getResource } from "../../helpers/resourceHelper";
import { graphql, useStaticQuery } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Link } from 'gatsby-plugin-react-i18next';
import {
  getAnimationDelay,
  getAnimationDuration,
} from "../../helpers/animationHelper";

const query = graphql`
  query newsItem {
    resources: allContentfulResource(
      filter: { key: { in: ["news.button.explore.label"] } }
    ) {
      edges {
        node {
          value {
            raw
          }
          node_locale
          key
        }
      }
    }
  }
`;

const NewsItem = ({ newItem }) => {
  const { language } = useI18next();
  const { resources } = useStaticQuery(query);
  const breakpoints = useBreakpoint();
  const layoutInfo = newItem.textPosition
  ? newItem.textPosition.split(" ")
  : ["Top", "Left"];
  const isTop = layoutInfo[0] === "Top";
  const isLeft = layoutInfo[1] === "Left";
  return (
    <div className="news-item__container">      
          <ImageWithFocalPoint
            image={newItem.mainImage}
            alt={newItem.mainImage.altText}
            className="news-item__image"
          />
          <div className="news-item__title-container">
            <Link className="news-item__title" to={newItem.slug}>
              {newItem.title}
            </Link>
            {!breakpoints.l && (
            <p className="news-item__date">
              {getMonthlyFormattedDate(newItem.publishDate, language)}
            </p>
            )}
          </div>
          {breakpoints.l && (
            <p className="news-item__date">
              {getMonthlyFormattedDate(newItem.publishDate, language)}
            </p>
            )}
          <p className="news-item__summary">{newItem.summary}</p>
          <div className="news-item__explore-button">
            <Button
              type="noBorder"
              title={documentToReactComponents(
                getResource(resources.edges, "news.button.explore.label", language)
              )}
              to={newItem.slug}
            />
          </div>
 </div>  
  );
};

export default NewsItem;
