import React from 'react'
import { graphql, useStaticQuery } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getResource } from "../../helpers/resourceHelper";
import { useI18next } from "gatsby-plugin-react-i18next";
import Button from "../button";
import { BLOCKS } from "@contentful/rich-text-types";
import '../../scss/components/generic/contactUs.scss';
import Map from './map';

const query = graphql`
  query contactUsQuery {
    resources: allContentfulResource(
      filter: { key: { in: [
          "contactus.header", 
          "contactus.description", 
          "contactus.ctabutton.label",
          "contactus.ctabutton.url",
          "contactus.map.locations.latitude",
          "contactus.map.locations.longitude"] } }
    ) {
      edges {
        node {
          value {
            raw
          }
          node_locale
          key
        }
      }
    }
  }
`;

function ContactUs() {
  const { resources } = useStaticQuery(query);
  const { language } = useI18next();

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (!children || children.length === 0 || !children[0]) {
          return <div className="contact-us__details-empty" />
        } else
          return <div className="contact-us__details-text">{children}</div>
      }
    }
  };

  return <div className="contact-us__container">
    <div className="contact-us__map-container">
      <Map
        className="contact-us__map"
        lat={Number.parseFloat(getResource(resources.edges, "contactus.map.locations.latitude", language, 'string'))}
        lng={Number.parseFloat(getResource(resources.edges, "contactus.map.locations.longitude", language, 'string'))} />
    </div>
    <div className="contact-us__details-container">
      <h2>{documentToReactComponents(getResource(resources.edges, "contactus.header", language))}</h2>
      <div className="contact-us__details">{documentToReactComponents(getResource(resources.edges, "contactus.description", language), options)}</div>
      <Button
        className="contact-us__button"
        title={getResource(resources.edges, "contactus.ctabutton.label", language, "string")}
        to={getResource(resources.edges, "contactus.ctabutton.url", language, "string")} />
    </div>
  </div >
}

export default ContactUs;
