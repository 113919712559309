import React from 'react'
import { renderRichText } from '../helpers/richTextHelper';
import ImageWithFocalPoint from "./imageWithFocalPoint";
import Button from './button';

const StepItem = ({ step, index }) => {
    return (<div key={index} className={`step-item ${index%2?'to-left':'to-right'}`} data-sal={index % 2 ? 'slide-right' : 'slide-left'} data-sal-delay="300" data-sal-duration="1000" data-sal-easing="ease">
        <ImageWithFocalPoint className="step-image" width={406} height={406} image={step.image} />
        <div className="step-item-description">
            <div className="seperator"></div>
            {step.indicator && <div className="step-item-number">{step.indicator}</div>}
            <h2 className="step-item-title">{step.title}</h2>
            <span className="step-item-text">{step.description ? renderRichText(step.description.raw) : null}</span>
            {step.ctaLabel && (<div className="step-item-cta-button">
                <Button title={step.ctaLabel} to={step.ctaUrl} type={`noBorder`} />
            </div>)}
        </div>
    </div>)
}

export default StepItem;