import React from 'react'
import '../../scss/components/generic/genericTextbox.scss';

const GenericTextbox = (props) => {
    const {errorMessage, ...rest} = props;

    return (
        <div className="generic-textbox">
            <input
                {...rest}
                type={`${props.type || 'text'}`}
                className={`generic-input ${props.className}${props.errorMessage ? ' invalid' : ''}`}>
            </input>
            {props.errorMessage && <span className="validation-box">{props.errorMessage}</span>}
        </div>
    );
}

export default GenericTextbox;
