import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import logo from '../images/Logo.png';
import { useI18next } from 'gatsby-plugin-react-i18next';

const query = graphql`
  query Seo {
    site {
      siteMetadata {
        description
        image
        title
        titleTemplate
        siteUrl
      }
    }
  }
`;


function Seo({ meta }) {
  const { language } = useI18next();
  const { site } = useStaticQuery(query);
  let seo = {
    ...site.siteMetadata,
    canonical: site.siteMetadata.siteUrl,
    image: logo,
    locale: language
  };

  if (meta !== 'undefined') {
    seo = { ...seo, ...meta };
    seo.url = site.siteMetadata.siteUrl + '/' + meta.url;
    seo.keywords = seo.keywords ? seo.keywords.join(', ') : '';
  }

  return (
    <Helmet title={seo.title} titleTemplate={seo.titleTemplate} htmlAttributes={{ lang: seo.locale.substring(0, 2) }}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="keywords" content={seo.keywords} />
      <link rel="canonical" href={seo.canonical} />
      <link rel="alternate" hreflang={seo.locale} href={seo.canonical} />

      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.locale && <meta property="og:locale" content={seo.locale} />}
      {seo.image && <meta property="og:image" content={seo.image} />}
    </Helmet>
  )
}

export default Seo;
