import React from "react"
import Fairs from "./fairs"
import FairsSnaphot from "./fairsSnapshot"
import Faq from "./faq"
import NewsList from "./news"
import NewsSnaphot from "./newsSnapshot"
import ReferenceList from "./referenceList"
import NewsletterSubscription from "./newsletter"
import ContactUs from "./contactUs"
import SearchResults from "./searchResults"
import ContactForm from "./contactForm"

// This component will be used for generic pages such as Fair List, News List, Fair Details etc.
const Generic = ({ data }) => {
  if (data) {
    switch (data.type) {
      case "Fair List":
        return <Fairs data={data} />

      case "Fairs Snapshot":
        return <FairsSnaphot data={data} />

      case "FAQ":
        return <Faq />

      case "News List":
        return <NewsList data={data} />

      case "News Snapshot":
        return <NewsSnaphot data={data} />

      case "Newsletter Subscription":
        return <NewsletterSubscription />

      case "References":
        return <ReferenceList data={data} />

      case "Contact Us":
        return <ContactUs />

      case "Search Results":
        return <SearchResults />
  
      case "Contact Form":
        return <ContactForm />

      default:
        return null
    }
  } else {
    return null
  }
}


export default Generic