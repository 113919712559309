import React from "react";
import "../scss/components/imageBoxes.scss";
import ImageWithFocalPoint from './imageWithFocalPoint';

const ImageBoxes = ({ data }) => {
    return (
        <div className="image-boxes-container">
            <div className="main-image-container">
                <ImageWithFocalPoint className='main-image' image={data.mainImage} />
            </div>
            <div className="left-image-container">
                <ImageWithFocalPoint className='left-image' image={data.leftImage} />
            </div>
            <div className="right-image-container">
                <ImageWithFocalPoint className='right-image' image={data.rightImage} />
            </div>
            <div className="bottom-image-container">
                <ImageWithFocalPoint className='bottom-image' image={data.bottomImage} />
            </div>
        </div>
    );
};

export default ImageBoxes;
