import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ImageWithFocalPoint from "../imageWithFocalPoint";
import { renderRichText } from "../../helpers/richTextHelper";
import "../../scss/components/list/itemBox.scss";
import { Link } from "gatsby-plugin-react-i18next";
import {
  getAnimationDelay,
  getAnimationDuration,
} from "../../helpers/animationHelper";

const ItemBox = ({ item, type, index }) => {
  return (
    <div
      key={index}
      className={`item-box__container${type === "Large Item Boxes" ? " xlarge" : " "}`}
      data-sal={index % 2 ? "slide-left" : "slide-right"}
      data-sal-delay={getAnimationDelay()}
      data-sal-duration={getAnimationDuration()}
      data-sal-easing="ease"
    >
      <div className="item-box__image-container">
        <ImageWithFocalPoint
          image={item.backgroundImage}
          alt={item.backgroundImage.altText}
          className="item-box__image"
        />
        <div className="item-box__opacity-background">
          <div className={`item-box__detail-container${item.descriptionOnHover ? " do-flade" : " "}`}>
            {!item.logo ? (
              <p className="item-box__item-title">{item.title}</p>
            ) : (
              <GatsbyImage
                image={getImage(item.logo)}
                alt={item.logo.description}
                className="item-box__logo"
              />
            )}

            {item.description && (
              <p className={`item-box__item-description${item.descriptionOnHover ? " closed" : " opened"}`}>
                {renderRichText(item.description.raw)}
              </p>
            )}
          </div>
          <Link className="v-button" to={item.ctaUrl}>
            {item.ctaLabel}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ItemBox;
