import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import "../../scss/components/generic/news.scss";
import languageHelper from "../../helpers/languageHelper";
import PaginatedList from "../paginatedList";
import NewsItem from "./newsItem";
import "../../scss/components/generic/news.scss";

const query = graphql`
  query News {
    news: allContentfulNews(
      limit: 100
      sort: { order: DESC, fields: publishDate }
    ) {
      nodes {
        description {
          raw
        }
        summary
        node_locale
        title
        slug
        metaKeywords
        publishDate
        mainImage {
          ... on ContentfulImageWithFocalPoint {
            ...FocalPointFragment
          }
        }
      }
    }
  }
`;

function NewsList() {
  const { news } = useStaticQuery(query);
  const { language } = useI18next();
  const newsList = languageHelper.getCurrentLanguageNodes(news, language);

  let renderNewsItem = (newItem, index) => {
    return <NewsItem newItem={newItem} index={index} />;
  };

  return (
    <>
      <PaginatedList
        className="news_container new-list"
        pageSize={4}
        items={newsList}
        mapItem={renderNewsItem}
      />
    </>
  );
}

export default NewsList;
