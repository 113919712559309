import React from "react";
import ImageWithFocalPoint from "../imageWithFocalPoint";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from 'gatsby-plugin-react-i18next';

const BrandBoxes = ({ item, index }) => {
  return (
    <div className="our-brands__brand-box">
      <Link to={item.slug}>
        <ImageWithFocalPoint
          className={`our-brands__brand-image${index === 1 ? " mid" : " "}`}
          image={item.image}
        />
      </Link>
      <Link to={item.slug}>
        <GatsbyImage
          className="our-brands__brand-logo"
          image={getImage(item.brandLogo)}
        />
      </Link>
    </div>
  );
};

export default BrandBoxes;
