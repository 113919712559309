import React from 'react'
import { connectHits, Highlight } from 'react-instantsearch-dom';
import Button from "./../button";
import CustomHighlight from './customHighlight';
import { getResource } from "../../helpers/resourceHelper";
import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import '../../scss/components/searchBar/noResult.scss';

const query = graphql`
  query buttonText {
    resources: allContentfulResource(
      filter: { key: { in: ["search.result.ctalabel", "search.result.page.ctalabel", "header.search.noresult"] } }
    ) {
      edges {
        node {
          value {
            raw
          }
          node_locale
          key
        }
      }
    }
  }
`;

const Results = connectHits(({ hits }) => {
  const { language } = useI18next();
  const { resources } = useStaticQuery(query);
  if (hits.length === 0) return (<div className="no-result-container"><div className="no-result-text">{documentToReactComponents(getResource(resources.edges, 'header.search.noresult', language))}</div></div>);
  return <div className="hits">{hits.map(hit => <div class="hit">
    <Highlight hit={hit} className="hit-title" attribute="name" />
    {hit.description && <CustomHighlight hit={hit} />}
    <Button title={getResource(resources.edges, hit.description ? "search.result.ctalabel" : "search.result.page.ctalabel", language, "string")} to={(hit.slug.startsWith("/") ? "" : "/") + hit.slug} />
    <hr />
  </div>
  )}</div>;
});

export default Results;