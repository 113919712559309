import React, { useState } from "react";
import "../scss/components/spotlightFocus.scss";
import {
  getAnimationDelay,
  getAnimationDuration,
} from "../helpers/animationHelper";
import { renderRichText } from "../helpers/richTextHelper";
import { Tab, Tabs, TabList } from "react-tabs";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import ReactSvg from "./Generic/reactSvg";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

function SpotlightFocus(data) {
  const [showDetails, setShowDetails] = useState([false, false, false, false]);
  const [selectedTab, setSelectedTab] = useState(0);
  const breakpoints = useBreakpoint();
  let hasDetailsPane = true;
  if (data.spotlightList) {
    var index = data.spotlightList.items.findIndex(
      (x) => x.hasDetailsPane === false
    );
    if (index !== -1) hasDetailsPane = false;
  }

  return (
    <Tabs
      className="spotlight__container"
      onSelect={(index) => {
        if (!hasDetailsPane) return;
        var showDetailsArray = [false, false, false, false];
        showDetailsArray[index] = true;
        //setShowDetails(true);
        setSelectedTab(index);
        if (index === selectedTab && showDetails[index]) {
          //setShowDetails(false);
          showDetailsArray[index] = false;
        }
        setShowDetails(showDetailsArray);
      }}
    >
      <TabList className="spotlight__navigation">
        {data.spotlightList &&
          data.spotlightList.items &&
          data.spotlightList.items.map((item, index) => {
            return [
              <Tab key={index} className="spotlight__tab-container">
                <div
                  role="button"
                  tabIndex={index}
                  key={index}
                  className={`spotlight-item ${
                    hasDetailsPane ? "" : "no-cursor"
                  }`}
                  data-sal="slide-up"
                  data-sal-delay={getAnimationDelay() + index * 200}
                  data-sal-duration={getAnimationDuration()}
                  data-sal-easing="ease"
                  onKeyDown={() => setShowDetails(!showDetails[index])}
                  onClick={() => setShowDetails(!showDetails[index])}
                >
                  <ReactSvg
                    url={item.logo.file.url}
                    className={`spotlight-image ${
                      showDetails[index] ? "" : "detail-panel-closed"
                    }`}
                  />
                  <div
                    className={`spotlight-title_container ${
                      showDetails[index] ? "" : "detail-panel-closed"
                    }`}
                  >
                    <p className="spotlight-title">{item.title}</p>
                    {hasDetailsPane && [
                      <BsChevronDown className="down-arrow" />,
                      <BsChevronUp className="up-arrow" />,
                    ]}
                  </div>
                </div>
                {showDetails[index] && (
                  <div className="spotlight__selected-tab-arrow" />
                )}
              </Tab>,
              <div>
                {breakpoints.md && index % 2
                  ? data.spotlightList.items &&
                    data.spotlightList.items.map((item, panelIndex) => {
                      return panelIndex <= index &&
                        index - panelIndex < 2 &&
                        showDetails[panelIndex] ? (
                        <div
                          key={panelIndex}
                          className="spotlight_panel-container"
                        >
                          <div className="spotlight-details_container">
                            <h2>{item.title}</h2>
                            <div className="spotlight-details_description">
                              <p className="spotlight-details_left-description">
                                {renderRichText(item.leftDescription.raw)}
                              </p>
                              <p className="spotlight-details_right-description">
                                {renderRichText(item.rightDescription.raw)}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      );
                    })
                  : ""}
              </div>,
            ];
          })}
      </TabList>
      {!breakpoints.md &&
        data.spotlightList.items &&
        data.spotlightList.items.map((item, panelIndex) => {
          return showDetails[panelIndex] ? (
            <div key={panelIndex} className="spotlight_panel-container">
              <div className="spotlight-details_container">
                <h2>{item.title}</h2>
                <div className="spotlight-details_description">
                  <p className="spotlight-details_left-description">
                    {renderRichText(item.leftDescription.raw)}
                  </p>
                  <p className="spotlight-details_right-description">
                    {renderRichText(item.rightDescription.raw)}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ""
          );
        })}
    </Tabs>
  );
}

export default SpotlightFocus;
