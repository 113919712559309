import React from "react";
import "../scss/components/quote.scss";
import {
  getAnimationDelay,
  getAnimationDuration,
} from ".././helpers/animationHelper";

const Quote = ({ data }) => {
  return (
    <div
              className="quote-container"
              data-sal="slide-up"
              data-sal-delay={getAnimationDelay("slow")}
              data-sal-duration={getAnimationDuration()}
              data-sal-easing="ease"
            >
      {data.description && (
        <div className="quote-description">{data.description.description}</div>
      )}
      {data.author && <div className="quote-author">{data.author}</div>}
    </div>
  );
};

export default Quote;
