import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { renderRichText } from '../../helpers/richTextHelper';
import languageHelper from "../../helpers/languageHelper";
import "../../scss/components/generic/faq.scss";

const query = graphql`
query Faqs {
  faqs: allContentfulFaqItem {
    nodes {
      question
      answer {
          raw
      }
      node_locale
    }
  }
}
`;

const Faq = () => {
    const { faqs } = useStaticQuery(query);
    const { language } = useI18next();
    const faqList = languageHelper.getCurrentLanguageNodes(faqs, language);

    return (
        <div className={"faq__container"}>
            {
                faqList && faqList.length > 0 && faqList.map((faqItem, index) => {
                    return (
                        <div className="faq__item-container">
                            <div className="faq__question">{faqItem.question}</div>
                            <div className="faq__answer">{renderRichText(faqItem.answer.raw)}</div>
                            {index !== (faqList.length - 1) && <hr />}
                        </div>
                    );
                })}
        </div>
    );
};

export default Faq;
