import dateConstants from "../constants/dateConstants";

export function getMonthlyFormattedDate(date, language) {
  const currentDate = new Date(date);
  if (currentDate) {
    return currentDate.toLocaleString(
      language,
      dateConstants.fairDetailDateFormat
    );
  }
  return "";
}

export default {
  getMonthlyFormattedDate: getMonthlyFormattedDate,
};
