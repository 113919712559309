import React, { useState } from 'react'
import { renderRichText } from '../helpers/richTextHelper';
import { BiPlay } from 'react-icons/bi';
import '../scss/components/video.scss';
import ImageWithFocalPoint from "./imageWithFocalPoint";
import ReactPlayer from 'react-player/lazy'
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const Video = (data) => {
    const breakpoints = useBreakpoint();

    const [isPlaying, setIsPlaying] = useState(false);

    return (
        <div className="video">
            {
                !isPlaying &&
                <div className="video__cover">
                    {data.data.hasGradientOverlay && <div className="gradient-overlay"></div>}
                    <ImageWithFocalPoint
                        objectFit="cover"
                        className="video__thumbnail-image"
                        image={data.data.thumbnail}
                        alt={data.data.thumbnail.altText}
                        style={{ position: "absolute" }} />
                    <div className="video__details">
                        <div className="video__play-btn" aria-label="video play button" tabIndex={0} role="button" onClick={() => setIsPlaying(true)} onKeyDown={() => setIsPlaying(true)}>
                            <BiPlay />
                        </div>
                        <div className="video__description">
                            {data.data.description && renderRichText(data.data.description.raw)}
                        </div>
                    </div>
                </div>
            }
            <div className="video__player">
                <ReactPlayer url={data.data.videoUrl} height={breakpoints.sm ? 215 : (breakpoints.md ? 350 : 550)} width="auto" playing={isPlaying} controls />
            </div>
        </div>
    )
}

export default Video