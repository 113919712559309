import React from "react";
import { renderRichText } from "../helpers/richTextHelper";
import "../scss/components/pageBanner.scss";
import ImageWithFocalPoint from "./imageWithFocalPoint";
import ReactPlayer from "react-player/lazy";
import ReactSlider from "react-slick";
import { getResponsiveSettings } from "../helpers/sliderHelper";
import NextArrow from "../images/svgs/next-arrow.svg";
import PrevArrow from "../images/svgs/prev-arrow.svg";
import Button from "./button";
import Brands from "./../constants/brands";

const PageBanner = ({ data, className, brandTheme }) => {
  const breakpoints = getResponsiveSettings();
  const mobileWidth =
    breakpoints && breakpoints.length > 1 ? breakpoints[3].breakpoint : "960";

  const slider = React.useRef(null);
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    focusOnSelect: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  function renderSlideItem(data, slide, index) {
    if (typeof slide === "string") {
      return (
        <>
          <div className="slider-overlay"></div>
          <ReactPlayer
            className="video-background"
            key={index}
            url={slide}
            muted={true}
            loop={true}
            playing={true}
            controls={false}
            playsinline={true}
          />
        </>
      );
    } else {
      return (
        <>
          {slide.hasGradientOverlay === true && (
            <div className="slider-overlay"></div>
          )}
          <ImageWithFocalPoint
            key={index}
            objectFit="cover"
            image={slide.image}
          />
          <div className="hero__page-banner__description">
            {slide.headline && renderRichText(slide.headline.raw)}
            {slide.description && renderRichText(slide.description.raw)}
            {slide.ctaLabel && (
              <Button
                title={slide.ctaLabel}
                to={slide.ctaUrl}
                link={`${
                  brandTheme == Brands.Catalogue.label ? "external" : ""
                }`}
              />
            )}
          </div>
        </>
      );
    }
  }

  const isBrowser = () => typeof document !== "undefined";
  if (data && !data.items && data.videoUrl) {
    data.items = [data.videoUrl];
  }
  if (!data || !data.items || data.items.length === 0) {
    if (isBrowser()) document.body.classList.add("no-page-banner");
    return <></>;
  } else {
    if (isBrowser() && document.body.classList.contains("no-page-banner"))
      document.body.classList.remove("no-page-banner");
  }

  if (data.isFullHeight === true && typeof data.items[0] !== "string") {
    const isBrowser = () => typeof window !== "undefined";
    if (isBrowser() && window.innerWidth <= mobileWidth) {
      if (data.mobileVideoUrl) data.items.unshift(data.mobileVideoUrl);
    } else {
      if (data.videoUrl) data.items.unshift(data.videoUrl);
    }
  }

  return (
    <ReactSlider
      ref={slider}
      {...settings}
      className={`hero__page-banner ${data.isFullHeight ? "full-height" : ""} ${
        className || ""
      }${brandTheme == Brands.Catalogue.label ? "catalogue" : ""}`}
    >
      {data.items.map((slide, i) => {
        return renderSlideItem(data, slide, i);
      })}
    </ReactSlider>
  );
};

export default PageBanner;
