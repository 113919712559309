import React from "react";
import RelatedItemBox from "./relatedBox";
import "../../scss/components/list/relatedBoxList.scss";
const RelatedBoxes = ({ data }) => {
  return (
    <div className="related-item-boxes__container">
      {data.headLine && (
        <h2 className="related-item-boxes__title">{data.headLine}</h2>
      )}

      <div className="related-item-boxes__item-container">
        {data.items &&
          data.items.map((item, index) => {
            return <RelatedItemBox item={item} index={index} />;
          })}
      </div>
    </div>
  );
};

export default RelatedBoxes;
