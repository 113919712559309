import React from "react";
import StepItem from "./stepItem";
import { renderRichText } from "../helpers/richTextHelper";
import {
  getAnimationDelay,
  getAnimationDuration,
} from "../helpers/animationHelper";
import "../scss/components/steps.scss";

const Steps = (data) => {
  return (
    <div className="step-container">
      {data.stepList.headLine && <div className="container-background"></div>}
      <div className="step-header-left" data-sal="slide-up"
        data-sal-delay={getAnimationDelay()}
        data-sal-duration={getAnimationDuration()}
        data-sal-easing="ease">
        {data.stepList.headLine && <h2>
          {data.stepList.headLine}
        </h2>}
        {data.stepList.description && <div className="step-description">
          {renderRichText(data.stepList.description.raw)}
        </div>}
      </div>
      <div className="step-item-container">
        {data.stepList.items.map((step, i) => {
          return <StepItem step={step} index={i + 1} />;
        })}
      </div>
    </div>
  );
};

export default Steps;
