import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { renderRichText } from "../../helpers/richTextHelper";
import { getResource } from "../../helpers/resourceHelper";
import ImageWithFocalPoint from "../imageWithFocalPoint";
import Button from "./../button";

const query = graphql`
  query viewMore {
    resources: allContentfulResource(
      filter: { key: { in: ["button.viewmore.label"] } }
    ) {
      edges {
        node {
          value {
            raw
          }
          node_locale
          key
        }
      }
    }
  }
`;

const ReferenceItem = ({ referenceItem, index }) => {
  const { language } = useI18next();
  const { resources } = useStaticQuery(query);

  return (
    <div className={`reference-item ${index % 2 ? "reverse" : ""}`}>
      <div className="image-column">
        <GatsbyImage
          image={getImage(referenceItem.logo)}
          width="145"
          className="reference-logo"
        />
        <ImageWithFocalPoint
          image={referenceItem.backgroundImage}
          className="reference-image"
        />
      </div>
      <div className="text-column">
        <p className="reference-title">{referenceItem.name}</p>
        <span className="reference-text">
          {renderRichText(referenceItem.subtitle.raw)}
        </span>
        {referenceItem.slug && (
          <Button
            to={referenceItem.slug}
            title={documentToReactComponents(
              getResource(resources.edges, "button.viewmore.label", language)
            )}
            type={`noBorder`}
          />
        )}
      </div>
    </div>
  );
};

export default ReferenceItem;
