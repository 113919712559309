import React from 'react'
import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import FairItem from './fairItem';
import '../../scss/components/generic/fairs.scss';
import languageHelper from '../../helpers/languageHelper';
import PaginatedList from '../paginatedList';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getResource } from "../../helpers/resourceHelper";
import Button from "../button";
import {
  getAnimationDelay,
  getAnimationDuration,
} from "../../helpers/animationHelper";

// Please set limit x languages. If you want 2 items, set 4 to limit since currently there are 2 languages. Maybe we might get 20 and filter in JS for 2 items. To be considered.
const query = graphql`
query FairsSnapshot {
  fairs: allContentfulFair(limit: 4, sort: {order: DESC, fields: startDate}) {
    nodes {
        description {
            raw
        }
        endDate
        location
        name
        node_locale
        startDate
        title
    }
  }
  resources: allContentfulResource(
      filter: {
        key: {
          in: [
            "fairs.button.seeAll.label"
            "fairs.header.label"
            "fairs.button.seeAll.link"
          ]
        }
      }
    ) {
      edges {
        node {
          value {
            raw
          }
          node_locale
          key
        }
      }
    }
}
`;

function FairsSnaphot() {
  const { fairs, resources } = useStaticQuery(query);
  const { language } = useI18next();
  const fairList = languageHelper.getCurrentLanguageNodes(fairs, language);

  let renderFairItem = (fair, index) => {
    return <FairItem fair={fair} index={index} />
  }

  return <div className="fairs-container">
    <div className="fairs_header-container">
                <h2 className="fairs_header"
                    data-sal="slide-left"
                    data-sal-delay={getAnimationDelay("slow")}
                    data-sal-duration={getAnimationDuration()}
                    data-sal-easing="ease">
                  {documentToReactComponents(
                    getResource(resources.edges, "fairs.header.label", language)
                  )}
                </h2>
                  
                <Button
                  className="hero-text"
                  animationDirection="slide-right"
                  animationDelay={getAnimationDelay("slow")}
                  title={documentToReactComponents(getResource(resources.edges, "fairs.button.seeAll.label", language))}
                  to={getResource(resources.edges, "fairs.button.seeAll.link", language, "string")} /> 
    </div>
            
           <PaginatedList  className="fairs_list_container"  
              pageSize={2} 
              items={fairList} 
              mapItem={renderFairItem} />
  </div>
}

export default FairsSnaphot;
