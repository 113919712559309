import React from "react";
import ImageWithFocalPoint from "../imageWithFocalPoint";
import "../../scss/components/list/navigationBox.scss";
import { Link } from "gatsby-plugin-react-i18next";
import {
  getAnimationDelay,
  getAnimationDuration,
} from "../../helpers/animationHelper";

const NavigationBox = ({ item, type, index }) => {
  return (
    <div
      key={index}
      className="navigation-box__container"
      data-sal={index % 2 ? "slide-left" : "slide-right"}
      data-sal-delay={getAnimationDelay()}
      data-sal-duration={getAnimationDuration()}
      data-sal-easing="ease"
    >
      <div className="navigation-box__image-container">
        <ImageWithFocalPoint
          image={item.backgroundImage}
          alt={item.backgroundImage.altText}
          className="navigation-box__image"
        />
        <div className="navigation-box__opacity-background">
          <div className="navigation-box__detail-container do-flade">
            <p className="navigation-box__item-title">{item.title}</p>
            <div className="navigation-box__item-description closed">
              {item.subNavigations &&
                item.subNavigations.map((item, index) => {
                  return (
                    item.slug ?
                    <Link
                      className="navigation-box__item-navigation"
                      to={item.slug}
                    >      
                      {item.title}
                    </Link>
                    :
                    <span className="navigation-box__item-navigation">{item.title}</span>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavigationBox;
