import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import "../../scss/components/generic/news.scss";
import languageHelper from "../../helpers/languageHelper";
import PaginatedList from "../paginatedList";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getResource } from "../../helpers/resourceHelper";
import NewsItem from "./newsItem";
import Button from "../button";
import {
  getAnimationDelay,
  getAnimationDuration,
} from "../../helpers/animationHelper";

//note that limit should be set for both languages below.
const query = graphql`
  query NewsSnapshot {
    newsEn: allContentfulNews(
      limit: 2
      filter: {node_locale: {eq: "en-US"}}
      sort: { order: DESC, fields: publishDate }
    ) {
      nodes {
        description {
          raw
        }
        node_locale
        title
        summary
        slug
        metaKeywords
        publishDate
        mainImage {
          ... on ContentfulImageWithFocalPoint {
            ...FocalPointFragment
          }
        }
      }
    }
    newsTr: allContentfulNews(
      limit: 2
      filter: {node_locale: {eq: "tr"}}
      sort: { order: DESC, fields: publishDate }
    ) {
      nodes {
        description {
          raw
        }
        node_locale
        title
        summary
        slug
        metaKeywords
        publishDate
        mainImage {
          ... on ContentfulImageWithFocalPoint {
            ...FocalPointFragment
          }
        }
      }
    }
    resources: allContentfulResource(
      filter: {
        key: {
          in: [
            "news.button.seeAll.label"
            "news.header.label"
            "news.button.seeAll.link"
          ]
        }
      }
    ) {
      edges {
        node {
          value {
            raw
          }
          node_locale
          key
        }
      }
    }
  }
`;

function NewsSnaphot() {
  const { language } = useI18next();
  const { newsTr, newsEn, resources } = useStaticQuery(query);

  const news = (language==="tr") ? newsTr : newsEn;
 
  const newsList = languageHelper.getCurrentLanguageNodes(news, language);

  let renderNewsItem = (newItem, index) => {
    return <NewsItem newItem={newItem} index={index} />;
  };

  return (
    <>
      <div className="news_header-container">

              <h2 className="news_header"
                  data-sal="slide-left"
                  data-sal-delay={getAnimationDelay("slow")}
                  data-sal-duration={getAnimationDuration()}
                  data-sal-easing="ease">
                {documentToReactComponents(
                  getResource(resources.edges, "news.header.label", language)
                )}
              </h2>
        <div
              className="hero-text"
              data-sal="slide-right"
              data-sal-delay={getAnimationDelay("slow")}
              data-sal-duration={getAnimationDuration()}
              data-sal-easing="ease"
          > 
            <Button
              title={getResource(
                resources.edges,
                "news.button.seeAll.label",
                language,
                "string"
              )}
              to={getResource(
                resources.edges,
                "news.button.seeAll.link",
                language,
                "string"
              )}
            />
         </div>
      </div>
      <PaginatedList
        className="news_container"
        pageSize={2}
        items={newsList}
        mapItem={renderNewsItem}
      ></PaginatedList>
    </>
  );
}

export default NewsSnaphot;
