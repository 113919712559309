import React from 'react'
import { connectHighlight } from 'react-instantsearch-dom';
import { renderRichText } from "../../helpers/richTextHelper";

const CustomHighlight = connectHighlight(({ highlight, attribute, hit }) => {
  const optionsWithShortener = {
    renderText: text => {
      if (text.search("ais-highlight") > -1)
        return <div dangerouslySetInnerHTML={{ __html: text }}></div>
      else
        return <></>
    },
  };

  const options = {
    renderText: text => {
        return <div dangerouslySetInnerHTML={{ __html: text }}></div>
    },
  };

  return (<div className="hit-description">{hit._highlightResult.description && renderRichText(hit._highlightResult.description.raw.value, (hit._highlightResult.description.raw.matchLevel == "none" || hit._highlightResult.description.raw.value.search('{\"nodeType\":\"document\"') > -1) ? optionsWithShortener : options)}</div>);
});

export default CustomHighlight;