import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ImageWithFocalPoint from "../imageWithFocalPoint";
import "../../scss/components/list/relatedBox.scss";
import { Link } from "gatsby-plugin-react-i18next";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const RelatedItemBox = ({ item, index }) => {
  const breakpoints = useBreakpoint();
  if (item.image) item.backgroundImage = item.image;
  return breakpoints.md ? (
    <Link
      className="related-box__item"
      role="button"
      key={index}
      to={item.ctaUrl}
    >
      {index % 2 ? (
        <div className="related-box__container">
          <div className="related-box__logo-container">
            {!item.logo ? (
              <p className="related-box__reference-title">{item.title}</p>
            ) : (
              <GatsbyImage
                image={getImage(item.logo)}
                alt={item.logo.description}
                className="related-box__logo"
              />
            )}
          </div>
          <div className="vertical-line" />
          <ImageWithFocalPoint
            image={item.image}
            alt={item.image.altText}
            className="related-box__image"
          />
          <div className="line" />
        </div>
      ) : (
        <div className="related-box__container">
          <ImageWithFocalPoint
            image={item.image}
            alt={item.image.altText}
            className="related-box__image"
          />
          <div className="related-box__logo-container">
            {!item.logo ? (
              <p className="related-box__reference-title">{item.title}</p>
            ) : (
              <GatsbyImage
                image={getImage(item.logo)}
                alt={item.logo.description}
                className="related-box__logo"
              />
            )}
          </div>
          <div className="line" />
        </div>
      )}
    </Link>
  ) : (
    <Link
      className="related-box__item horizontal"
      role="button"
      key={index}
      to={item.ctaUrl}
    >
      <div className="related-box__container horizontal">
        <ImageWithFocalPoint
          image={item.image}
          alt={item.image.altText}
          className="related-box__image horizontal"
        />
        <div className="line horizontal" />
        <div className="related-box__logo-container horizontal">
          {!item.logo ? (
            <p className="related-box__reference-title">{item.title}</p>
          ) : (
            <GatsbyImage
              image={getImage(item.logo)}
              alt={item.logo.description}
              className="related-box__logo"
            />
          )}
        </div>
      </div>
    </Link>
  );
};

export default RelatedItemBox;
