import React from "react";
import { renderRichText } from "../../helpers/richTextHelper";
import "../../scss/components/list/itemBoxes.scss";
import ItemBox from "./itemBox";
import ReferenceBox from "./referenceBox";
import NavigationBox from "./navigationBox";
import {
  getAnimationDelay,
  getAnimationDuration,
} from "../../helpers/animationHelper";
import Button from "../button";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const ItemBoxes = ({ data }) => {
  const breakpoints = useBreakpoint();
  function renderBoxItem(type, item, index) {
    switch (type) {
      case "Reference Boxes":
        return <ReferenceBox item={item} type={type} index={index} />;
      case "Item Boxes":
      case "Large Item Boxes":
        return <ItemBox item={item} type={type} index={index} />;
      case "Navigation Boxes":
        return <NavigationBox item={item} type={type} index={index} />;
      default:
        return null;
    }
  }
  return (
    <div className="item-boxes__container">
      {(data.headLine || data.description) && (
        <div
          className={`item-boxes__header-container${
            data.type !== "Navigation Boxes" ? " center" : " "
          }
          ${
            data.type == "Navigation Boxes" ? " nav-box" : " "
          }`}
        >
          <div className="item_boxes__header">
            {data.headLine && (
              <h2
                className={`item-boxes__title${
                  data.type === "Navigation Boxes" ? " large" : " "
                }`}
                data-sal="slide-up"
                data-sal-delay={getAnimationDelay()}
                data-sal-duration={getAnimationDuration()}
                data-sal-easing="ease"
              >
                {data.headLine}
              </h2>
            )}
            {!breakpoints.md &&
              data.type === "Navigation Boxes" &&
              data.ctaUrl && <Button title={data.ctaLabel} to={data.ctaUrl} />}
          </div>
          {data.description && (
            <p
              className={`item-boxes__description${
                data.type !== "Navigation Boxes" ? " center" : " "
              }`}
              data-sal="slide-up"
              data-sal-delay={getAnimationDelay()}
              data-sal-duration={getAnimationDuration()}
              data-sal-easing="ease"
            >
              {renderRichText(data.description.raw)}
            </p>
          )}
          {breakpoints.md && data.type === "Navigation Boxes" && data.ctaUrl && (
            <Button title={data.ctaLabel} to={data.ctaUrl} />
          )}
        </div>
      )}
      {data.type === "Reference Boxes" && (
        <div className="item-boxes__references-container">
          {data.items &&
            data.items.map((item, index) => {
              return renderBoxItem(data.type, item, index);
            })}
        </div>
      )}
      {data.type !== "Reference Boxes" && (
      <div className="item-boxes__item-container">
        {data.items &&
          data.items.map((item, index) => {
            return renderBoxItem(data.type, item, index);
          })}
      </div>
      )}
      {data.type !== "Navigation Boxes" && data.ctaUrl && (
        <Button title={data.ctaLabel} to={data.ctaUrl} />
      )}
    </div>
  );
};

export default ItemBoxes;
